import {useEffect, useRef} from 'react';
import {ComponentPainter, COMPONENT_TYPES} from 'shared';

import {cloneDeep, debounce} from 'lodash';
import {previewSubpanels, validSubpanels} from 'shared/helpers';
import {HingeBlack, HingeWhite} from 'assets';
import excel from 'shared/Excel';
import {useAppContext} from 'contexts';
import {useAppSelector} from 'store/customer';
import {
    selectActiveHorizontalMidRail,
    selectActiveVerticalMidRail,
} from 'components/customer/QFPRedux/store/qfpSlice';

export const useQFPPreview = (
    product_,
    showSpecs_,
    previewOptions_,
    id,
    validationData_,
    rotateEdgeFields
) => {
    const {userProfile} = useAppContext();
    const activeHorizontalMidrails = useAppSelector((state) =>
        selectActiveHorizontalMidRail(state, id)
    );
    const activeVerticalMidrails = useAppSelector((state) =>
        selectActiveVerticalMidRail(state, id)
    );

    const updatePreview = ({
        product,
        previewOptions_,
        validationData,
        componentPainter,
        rotateEdgeFields,
        activeHorizontalMidrails,
        activeVerticalMidrails,
    }) => {
        if (!product.isDeleted) {
            const productRef = cloneDeep(product);

            let topEdge = 0;
            let bottomEdge = 0;
            let leftEdge = 0;
            let rightEdge = 0;

            let materialImage;
            let materialEdgeImage;
            const isAdvanced = validationData && validationData.isAdvanced;
            const noneHingeStyle =
                productRef?.drill_only_hinge_name?.toLowerCase() == 'none';

            const previewOptions = cloneDeep(previewOptions_);
            Object.keys(previewOptions).forEach((key) => {
                if (
                    typeof previewOptions[key] == 'string' &&
                    key !== 'preview'
                ) {
                    previewOptions[key] = excel.calculate(previewOptions[key], {
                        ...product,
                        ...{
                            cabinet_door: {
                                advanced: validationData.isAdvanced,
                            },
                        },
                        ...validationData,
                    });
                }
            });

            if (previewOptions_.extMaterial) {
                if (
                    previewOptions_.extMaterial.image.indexOf(
                        'uploads/gocabinet_materials'
                    ) > -1
                ) {
                    materialImage = `/${previewOptions_.extMaterial.image}`;
                } else {
                    materialImage = `/uploads/gocabinet_materials/${previewOptions_.extMaterial.image}`;
                }
            }

            if (previewOptions_.extEdge) {
                if (
                    previewOptions_.extEdge.image.indexOf(
                        'uploads/gocabinet_materials'
                    ) > -1
                ) {
                    materialEdgeImage = `/${previewOptions_.extEdge.image}`;
                } else {
                    materialEdgeImage = `/uploads/gocabinet_materials/${previewOptions_.extEdge.image}`;
                }
            }

            const borders = {
                top: productRef.border_width_top
                    ? parseInt(productRef.border_width_top)
                    : null,
                bottom:
                    productRef.border_width_bottom &&
                    previewOptions.hasBottomBorders
                        ? parseInt(productRef.border_width_bottom)
                        : null,
                left: productRef.border_width_left
                    ? parseInt(productRef.border_width_left)
                    : null,
                right: productRef.border_width_right
                    ? parseInt(productRef.border_width_right)
                    : null,
            };

            if (previewOptions.hasEdging) {
                if (previewOptions.isPanel) {
                    if (productRef.hasOwnProperty('cabinet_edge_l1'))
                        topEdge = parseInt(productRef.cabinet_edge_l1);
                    if (productRef.hasOwnProperty('cabinet_edge_l2'))
                        bottomEdge = parseInt(productRef.cabinet_edge_l2);
                    if (productRef.hasOwnProperty('cabinet_edge_w1'))
                        leftEdge = parseInt(productRef.cabinet_edge_w1);
                    if (productRef.hasOwnProperty('cabinet_edge_w2'))
                        rightEdge = parseInt(productRef.cabinet_edge_w2);
                } else {
                    if (productRef.hasOwnProperty('panel_edge_top'))
                        topEdge = parseInt(productRef.panel_edge_top);
                    if (productRef.hasOwnProperty('panel_edge_bottom'))
                        bottomEdge = parseInt(productRef.panel_edge_bottom);
                    if (productRef.hasOwnProperty('panel_edge_left'))
                        leftEdge = parseInt(productRef.panel_edge_left);
                    if (productRef.hasOwnProperty('panel_edge_right'))
                        rightEdge = parseInt(productRef.panel_edge_right);
                }

                if (rotateEdgeFields) {
                    [topEdge, bottomEdge, leftEdge, rightEdge] = [
                        leftEdge,
                        rightEdge,
                        bottomEdge,
                        topEdge,
                    ];
                }
            }

            let options = {
                type: COMPONENT_TYPES.QFP,
                qfpType: productRef.cabinet_type,
                height: parseInt(productRef.height ? productRef.height : 0),
                width: parseInt(productRef.width ? productRef.width : 0),
                edgeMaterial: materialEdgeImage,
                exteriorMaterial: materialImage,
                edging: {
                    topEdge: topEdge,
                    bottomEdge: previewOptions.hasBottomBorders
                        ? bottomEdge
                        : 0,
                    leftEdge: leftEdge,
                    rightEdge: rightEdge,
                },
                hasBorders: previewOptions.hasBorders,
                hasCutout: previewOptions.hasCutout,
                borders: borders,
                horizontal: productRef.hor_grain == 1,
                metric: 'mm',
                hingeBlack: HingeBlack,
                hingeWhite: HingeWhite,
                drillings:
                    productRef.drillings && !noneHingeStyle
                        ? productRef.drillings
                        : [],
                doorHangReverse: userProfile?.doorHangReversal == 1,
            };

            if (
                previewOptions.hasDoorType &&
                productRef.hasOwnProperty('door_hang_type')
            ) {
                options['hangType'] = productRef.door_hang_type;

                if (productRef.door_hang_type == 3) {
                    if (productRef.cabinet_width_door_2 > 0) {
                        options['widths'] = [
                            productRef.cabinet_width_door_1,
                            productRef.cabinet_width_door_2,
                        ];
                    }
                } else {
                    options['widths'] = [productRef.width, productRef.width];
                }
            }

            if (
                previewOptions.hasDoorType &&
                productRef.hasOwnProperty('door_hang') &&
                previewOptions.hasDoorHang
            ) {
                options['doorHang'] = productRef.door_hang;
            }

            if (previewOptions.preview === COMPONENT_TYPES.DOOR && isAdvanced) {
                const verticalAmount = productRef.hasOwnProperty('vert_amount')
                    ? parseInt(productRef.vert_amount)
                    : 0;
                const horizontalAmount = productRef.hasOwnProperty(
                    'hori_amount'
                )
                    ? parseInt(productRef.hori_amount)
                    : 0;
                const horizontalPositions = productRef.hasOwnProperty(
                    'hori_mid_rail_positions'
                )
                    ? JSON.parse(
                          JSON.stringify(productRef.hori_mid_rail_positions)
                      )
                    : [];
                const verticalPositions = productRef.hasOwnProperty(
                    'vert_mid_rail_positions'
                )
                    ? JSON.parse(
                          JSON.stringify(productRef.vert_mid_rail_positions)
                      )
                    : [];

                options = {
                    type: COMPONENT_TYPES.DOOR,
                    doorHang: previewOptions.hasDoorHang
                        ? parseInt(productRef.door_hang)
                        : 2,
                    doorType: parseInt(productRef.door_hang_type),
                    height: parseInt(productRef.height ? productRef.height : 0),
                    width: parseInt(productRef.width ? productRef.width : 0),
                    doorTop: parseInt(productRef.doorTop),
                    doorBottom: parseInt(productRef.doorLeft),
                    doorLeft: parseInt(productRef.doorRight),
                    doorRight: parseInt(productRef.cabinet_door_right),
                    borderTop: productRef.border_width_top
                        ? parseInt(productRef.border_width_top)
                        : null,
                    borderLeft: productRef.border_width_left
                        ? parseInt(productRef.border_width_left)
                        : null,
                    borderBottom: productRef.border_width_bottom
                        ? parseInt(productRef.border_width_bottom)
                        : null,
                    borderRight: productRef.border_width_right
                        ? parseInt(productRef.border_width_right)
                        : null,
                    panelEdgeTop: topEdge,
                    panelEdgeLeft: leftEdge,
                    panelEdgeBottom: bottomEdge,
                    panelEdgeRight: rightEdge,
                    midRailHorizontalAmount: horizontalAmount,
                    midRailHorizontalHeight: productRef.hasOwnProperty(
                        'hori_height'
                    )
                        ? parseInt(productRef.hori_height)
                        : 0,
                    midRailVerticalAmount: verticalAmount,
                    midRailVerticalWidth: productRef.hasOwnProperty(
                        'vert_width'
                    )
                        ? parseInt(productRef.vert_width)
                        : 0,
                    midRailHorizontalPositions: horizontalPositions,
                    midRailVerticalPositions: verticalPositions,
                    glassSubPanels: previewSubpanels(
                        productRef.hasOwnProperty('glassSubPanels')
                            ? validSubpanels(productRef.glassSubPanels)
                            : Array(3).fill(Array(3).fill(false)),
                        verticalAmount,
                        horizontalAmount
                    ),
                    edgeMaterial: materialEdgeImage,
                    exteriorMaterial: materialImage,
                    barsVertical: productRef.hasOwnProperty('no_vert_bars')
                        ? productRef.no_vert_bars
                        : 0,
                    barsHorizontal: productRef.hasOwnProperty('no_hori_bars')
                        ? productRef.no_hori_bars
                        : 0,
                    barsWidth: productRef.hasOwnProperty('bar_width')
                        ? parseFloat(productRef.bar_width)
                        : 0,
                    horizontal: productRef.hor_grain == 1,
                    widths: options.hasOwnProperty('widths')
                        ? options.widths
                        : [],
                    hingeBlack: HingeBlack,
                    hingeWhite: HingeWhite,
                    drillings:
                        productRef.drillings && !noneHingeStyle
                            ? productRef.drillings
                            : [],
                    doorHangReverse: userProfile?.doorHangReversal == 1,
                    midRailIndicator: userProfile?.midRailIndicator,
                };
            } else if (previewOptions.preview === COMPONENT_TYPES.DRAWER) {
                options = {
                    type: COMPONENT_TYPES.DRAWER,
                    height: parseInt(productRef.height ? productRef.height : 0),
                    width: parseInt(productRef.width ? productRef.width : 0),
                    drawers: productRef.drawer_face_height.map(
                        (faceHeight) => ({drawer_face_height: faceHeight})
                    ),
                    borderTop: productRef.border_width_top
                        ? parseInt(productRef.border_width_top)
                        : null,
                    borderLeft: productRef.border_width_left
                        ? parseInt(productRef.border_width_left)
                        : null,
                    borderBottom: productRef.border_width_bottom
                        ? parseInt(productRef.border_width_bottom)
                        : null,
                    borderRight: productRef.border_width_right
                        ? parseInt(productRef.border_width_right)
                        : null,
                    hasBorders: isAdvanced && previewOptions.hasBorders,
                    panelEdgeTop: topEdge,
                    panelEdgeLeft: leftEdge,
                    panelEdgeBottom: bottomEdge,
                    panelEdgeRight: rightEdge,
                    faceType:
                        productRef.drawer_face_height.length > 1
                            ? productRef.drawer_face_type
                            : 1, // 2 is collective face
                    joiningBorderHeight:
                        productRef.drawer_face_height.length > 1
                            ? productRef.hori_height
                            : false,
                    joiningBorder: productRef.panel_edge_join,
                    edgeMaterial: materialEdgeImage,
                    exteriorMaterial: materialImage,
                    gap:
                        productRef.drawer_face_height.length > 1
                            ? productRef.cabinet_drawer_gap
                            : 0,
                    isQFP: true,
                    horizontal: productRef.hor_grain == 1,
                    doorHangReverse: userProfile?.doorHangReversal == 1,
                    allowDrawerFaceEdgeFinish:
                        userProfile?.allowDrawerFaceEdgeFinish,
                    drawerPanelEdges: productRef.drawer_panel_edges,
                    drawerAmount: productRef.drawer_amount,
                };
            }

            if (typeof activeHorizontalMidrails !== 'undefined') {
                options.activeHorizontalMidrails = activeHorizontalMidrails;
            }

            if (typeof activeVerticalMidrails !== 'undefined') {
                options.activeVerticalMidrails = activeVerticalMidrails;
            }

            componentPainter.init(options);
        }
    };

    const debouncedPreviewUpdate = useRef(
        debounce(updatePreview, 500, {
            leading: false,
            trailing: true,
            maxWait: 500,
        })
    ).current;

    useEffect(() => {
        if (showSpecs_) {
            let previewHeight =
                previewOptions_.preview == 'Drawer' ||
                (previewOptions_.preview == 'Door' &&
                    validationData_ &&
                    validationData_.hasOwnProperty('isAdvanced') &&
                    validationData_.isAdvanced)
                    ? 380
                    : 200;

            previewHeight = previewHeight + 40;

            const componentPainter = new ComponentPainter(
                `canvas-container-${id}`,
                previewHeight,
                310,
                true
            );

            debouncedPreviewUpdate({
                product: product_,
                previewOptions_,
                validationData: validationData_,
                componentPainter,
                rotateEdgeFields,
                activeHorizontalMidrails,
                activeVerticalMidrails,
            });
        }
    }, [
        validationData_,
        previewOptions_,
        showSpecs_,
        product_,
        activeHorizontalMidrails,
        activeVerticalMidrails,
    ]);
};
