import React, {useRef, useState, useEffect} from 'react';
import {searchJob} from 'service';
import {throttle} from 'lodash';
import {useAppContext} from 'contexts';

const delay = 3000;

const jobSearch = () => {
    const abortController = useRef<AbortController>(null);

    const {
        setSideBarOverlays,
        searchInput,
        resultReceived,
        setResultReceived,
        searchOnText,
        setsearchOnText,
        searchOnButton,
        setsearchOnButton,
        isSearching,
        setisSearching,
    } = useAppContext();

    const [resultData, setResultData] = useState([]);

    const throttleSearch = throttle(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            executeSearch(event);
        },
        delay,
        {
            leading: false,
            trailing: true,
        }
    );

    /**
     * Handles Throttle before executing Search
     */
    const callSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (['ArrowDown', 'ArrowLeft'].includes(event.key)) return;

        if (event.key === 'Escape') {
            throttleSearch.cancel();
            resetResults(true);
            event.currentTarget.blur();
            return;
        }

        throttleSearch.cancel();
        setisSearching(false);
        setResultReceived(false);
        cancelRequest();

        if (event.key === 'Enter') {
            executeSearch(event);
        } else {
            throttleSearch(event);
        }
    };

    /**
     * Cancels XHR Request
     */
    const cancelRequest = () =>
        abortController.current && abortController.current.abort();

    /**
     * Execute Search!
     * Only initiate when:
     *
     * 1. Enter is pressed
     * 2. 3 second no activity
     * 3. 3 Characters minimum
     * 4. Cancel Request, When new keypress activity
     *
     */
    const executeSearch = async (
        event: React.KeyboardEvent<HTMLInputElement>
    ) => {
        const target = event.currentTarget;
        if (target.value.length < 3) return;

        setSideBarOverlays(true);
        setsearchOnButton('searchOnButton');
        setsearchOnText('searchOnText');
        setisSearching(true);
        setResultReceived(false);

        abortController.current = new AbortController();

        const searchData = Object.values(
            await searchJob(target.value, abortController.current.signal)
        );

        setResultData(searchData);
        setisSearching(false);
        setResultReceived(true);
    };

    /**
     * Reset Current Status of Search Bar
     */
    const resetResults = (reset = false) => {
        if (searchInput.current.value == '' || reset) {
            setResultReceived(false);
            setResultData([]);
            setisSearching(false);
            setSideBarOverlays(false);
            setsearchOnButton('');
            setsearchOnText('');
            cancelRequest();
            searchInput.current.value = '';
        }
    };

    useEffect(() => {
        return () => {
            throttleSearch.cancel();
        };
    }, [resultData, isSearching, resultReceived]);

    return {
        resultData,
        resetResults,
        callSearch,
        searchOnText,
        searchOnButton,
        isSearching,
    };
};

export {jobSearch};
