import {useEffect, useRef, useState} from 'react';
import {
    getAdvancedAttributes,
    getBreadCrumb,
    getCategories,
    getFavouriteItems,
    getHardwareVariations,
    getItem,
    getItems,
    getProductBreadcrumb,
    getProductStyles,
    getQuickProducts,
    saveItemToJob,
    searchItems,
    updateFavourite,
    getProductDetails,
} from 'service';
import {
    useJobContext,
    useNotificationContext,
    useProductContext,
} from 'contexts';
import {
    genericMessageHandler,
    productFilter,
    usePromise,
    useQueryParams,
} from 'shared/helpers';
import {useNavigate, useParams} from 'react-router-dom';
import {useStickyCanvas} from 'hooks';
import {useFormikContext} from 'formik';
import {ComponentPainter, COMPONENT_TYPES} from 'shared';
import {throttle, uniqueId, cloneDeep} from 'lodash';
import {
    useGetJobSundryItemsQuery,
    invalidateJobSundryItems,
    useGetJobSundryAttributesQuery,
    invalidateJobSundryAttributes,
} from 'components/customer/Hardware/store/JobSundryItemsApi';
import {useAppDispatch} from 'store/customer';
import {
    invalidateJob,
    invalidateJobCost,
    invalidateTotalProductCount,
} from 'components/customer/Job/store/jobApi';
import {useSelector} from 'react-redux';
import {Session} from 'components/customer/Auth/store/authSlice';
export const useSaveToCartHandler = () => {
    const {notify} = useNotificationContext();
    const {job} = useJobContext();
    const dispatch = useAppDispatch();
    const AddToCart = async (
        sundry,
        quantity,
        advancedAttributeValues,
        attributeIdStore,
        itemVariation,
        jobSundryId
    ) => {
        let formData = new FormData();

        formData.append('sundry_item_id', sundry.id);
        formData.append('job_id', job.id);
        formData.append('sundry_item_code', sundry.code);
        formData.append('sundry_item_calculated_price', sundry.regularPrice);
        formData.append('sundry_item_quantity', quantity);
        formData.append('id', jobSundryId);
        if (sundry.advanced) {
            if (Object.keys(itemVariation).length) {
                formData.set(
                    'sundry_item_calculated_price',
                    itemVariation.regularPrice
                );
            }

            formData.append('sundry_item_advanced', '1');

            Object.keys(advancedAttributeValues).forEach((key) => {
                formData.append(key, attributeIdStore[key]);
                formData.append(
                    `attribute_values[${key}]`,
                    advancedAttributeValues[key]
                );
            });
        } else {
            formData.append('sundry_item_advanced', '0');
        }

        try {
            await saveItemToJob(formData);
            dispatch(invalidateTotalProductCount());
            dispatch(invalidateJob());
            dispatch(invalidateJobCost());
            dispatch(invalidateJobSundryItems());
            dispatch(invalidateJobSundryAttributes());
            genericMessageHandler(
                notify,
                {
                    message: jobSundryId
                        ? 'Item successfully updated'
                        : 'Item successfully added to your cart',
                },
                'success'
            );
        } catch (e) {
            genericMessageHandler(notify, e);
        }
    };

    return {
        AddToCart,
    };
};

export const useFavouriteHandler = () => {
    const {notify} = useNotificationContext();

    const AddToFavourite = async (item, isNew = true, isProduct) => {
        try {
            await updateFavourite(item.id, isNew, isProduct);

            genericMessageHandler(
                notify,
                {
                    message: `Item successfully ${
                        isNew ? 'added to' : 'removed from'
                    } your favourite list.`,
                },
                'success'
            );
        } catch (e) {
            genericMessageHandler(notify, e);
        }
    };

    return {
        AddToFavourite,
    };
};

export const useBreadcrumb = () => {
    const session = useSelector((state: AppState) => state.auth.session);

    const navigate = useNavigate();
    const {jobId, roomId} = useParams();
    const {product, category, subCategory, favourites} = useQueryParams();
    const [breadcrumbs, setBreadcrumbs] = useState([]);

    const addBreadCrumb = (item) => {
        if (Array.isArray(item)) setBreadcrumbs(item);
        else setBreadcrumbs([item]);
    };

    const removeBreadCrumb = (removeKey) => {
        const filteredBreadCrumb = breadcrumbs.filter(
            (item) => item.key !== removeKey
        );
        setBreadcrumbs(filteredBreadCrumb);

        if (filteredBreadCrumb.length) {
            navigate(filteredBreadCrumb[filteredBreadCrumb.length - 1].link);
        } else {
            if (roomId) navigate(`/v2/job/${jobId}/room/${roomId}/product`);
            else navigate(`/v2/job/${jobId}/hardware`);
        }
    };

    const popBreadCrumb = (index) => {
        if (typeof index !== 'undefined') breadcrumbs.length = index + 1;
        else breadcrumbs.pop();

        setBreadcrumbs(breadcrumbs);
    };

    const resetBreadCrumb = (items = []) => {
        setBreadcrumbs(items);
    };

    const fetchBreadcrumbs = async (
        product = false,
        category = false,
        subCategory = false,
        favourites = false
    ) => {
        if (favourites) {
            setBreadcrumbs([
                {
                    key: 'Favourites',
                },
            ]);
        } else {
            const productStyles = await getProductStyles();

            let productDetails;

            if (product) {
                productDetails = await getProductDetails(product);

                if (productDetails.style) {
                    category = productDetails.style;
                }

                if (productDetails.sub_style_id) {
                    subCategory = productDetails.sub_style_id;
                }
            }

            const breadCrumbs = await getProductBreadcrumb(
                category,
                subCategory,
                productStyles
            );

            if (category) {
                let mappedBreadCrumbs = [];

                if (roomId) {
                    if (breadCrumbs.length > 0) {
                        mappedBreadCrumbs = mapBreadcrumb(
                            breadCrumbs,
                            jobId,
                            roomId
                        );
                    }
                } else {
                    if (breadCrumbs.length > 1) {
                        mappedBreadCrumbs = mapBreadcrumb(
                            breadCrumbs,
                            jobId,
                            roomId
                        );
                    }
                }

                if (productDetails) {
                    mappedBreadCrumbs.push({
                        key: productDetails.name,
                    });
                }

                setBreadcrumbs(mappedBreadCrumbs);
            }
        }
    };

    useEffect(() => {
        setBreadcrumbs([]);
        if (session === Session.LOGGED_IN) {
            fetchBreadcrumbs(product, category, subCategory, favourites);
        }
    }, [product, category, subCategory, favourites, session]);

    return {
        breadcrumbs,
        addBreadCrumb,
        removeBreadCrumb,
        popBreadCrumb,
        resetBreadCrumb,
    };
};

export const mapBreadcrumb = (breadCrumb, jobId, roomId = false) => {
    const searchKeys = [];

    Array.isArray(breadCrumb) &&
        breadCrumb.length &&
        breadCrumb.forEach((item) => {
            let link = `/v2/job/${jobId}/hardware?category=${item.id}`;

            if (roomId) {
                link = `/v2/job/${jobId}/room/${roomId}/product?category=${item.id}`;

                if (item.hasOwnProperty('category')) {
                    link = `/v2/job/${jobId}/room/${roomId}/product?category=${item.category}&subCategory=${item.id}`;
                }
            }

            searchKeys.push({
                link,
                key: item.name,
                id: item.id,
            });
        });

    return searchKeys;
};

const favouriteBreadcrumb = (jobId, roomId) => {
    if (roomId) {
        return [
            {
                link: `/v2/job/${jobId}/room/${roomId}/product?favourites=1`,
                key: 'Favourite',
            },
        ];
    } else {
        return [
            {
                link: `/v2/job/${jobId}/hardware?favourites=1`,
                key: 'Favourite',
            },
        ];
    }
};

export const useCategoriesAndItems = () => {
    const {jobId, roomId} = useParams();

    if (roomId) {
        return useProductCategoriesAndItems(jobId, roomId);
    } else {
        return useHardwareCategoriesAndItems(jobId);
    }
};

const useProductCategoriesAndItems = (jobId, roomId) => {
    const [categories, setCategories] = useState([]);
    const [items, setItems] = useState([]);
    const [loader, setLoader] = useState(true);
    const {notify} = useNotificationContext();
    const {search, setSearch} = useProductContext();
    const {
        favourites,
        category = false,
        subCategory = false,
        products,
    } = useQueryParams();
    const navigate = useNavigate();

    const showAll = () => {
        navigate('?products=all');
    };

    useEffect(() => {
        setLoader(true);
        setItems([]);
        setCategories([]);

        const promises = {};
        promises.categories = getProductStyles();

        if (
            products === 'all' ||
            category ||
            subCategory ||
            favourites ||
            search
        ) {
            promises.items = getQuickProducts();
        }

        const promiseHandler = ({categories, items}) => {
            if (categories) {
                // list of categories from service
                if (category && !subCategory) {
                    // category is selected category from url
                    const subCategories = categories.filter(
                        (filterCategory) => {
                            return category == filterCategory.id;
                        }
                    );

                    if (subCategories.length && subCategories[0].subStyles) {
                        const subStylesList = subCategories[0].subStyles.map(
                            (subCategory) => ({
                                name: subCategory.subStyleName,
                                imageUrl: subCategory.subStyleImage,
                                isSubCategory: true,
                                id: subCategory.subStyleId,
                            })
                        );

                        setCategories(subStylesList);
                    } else {
                        setCategories([]);
                    }
                }

                if (!category && !subCategory) {
                    const updatedCategories = categories.map((category) => ({
                        name: category.styleName,
                        imageUrl: category.styleImage,
                        ...category,
                    }));

                    if (!(products && products === 'all')) {
                        updatedCategories.push({
                            noImage: true,
                            name: 'SHOW<br />ALL',
                            imageUrl: '',
                            action: showAll,
                        });
                    }

                    setCategories(updatedCategories);
                }
            }

            if (items) {
                let checkFavourites = false;
                const checkCategories = [];
                const checkSubCategories = [];

                if (favourites) checkFavourites = true;
                if (category) checkCategories.push(parseInt(category));
                if (subCategory) checkSubCategories.push(parseInt(subCategory));

                items = productFilter(
                    items,
                    checkFavourites,
                    checkCategories,
                    checkSubCategories,
                    search
                );
                if (items.length === 0) {
                    throw new Error();
                }

                const sortedItems = items
                    .map((item) => ({
                        ...item,
                        name: item.text,
                        imageUrl: item.image,
                        sortingName: item.changed_name || item.text,
                    }))
                    .sort((productA, productB) =>
                        productA.sortingName.localeCompare(productB.sortingName)
                    );

                setItems(sortedItems);
            }

            setLoader(false);
        };

        const errorHandler = (error) => {
            genericMessageHandler(
                notify,
                {
                    message: `No Items found with the keyword "${search}". Please try another term.`,
                },
                'warning',
                false
            );
            setSearch('');
            setLoader(false);
        };

        usePromise(promiseHandler, promises, errorHandler);
    }, [category, subCategory, search, favourites, products]);

    return {loader, categories, items};
};

const useHardwareCategoriesAndItems = (jobId) => {
    const [categories, setCategories] = useState([]);
    const [items, setItems] = useState([]);
    const [loader, setLoader] = useState(false);
    const {notify} = useNotificationContext();
    const {breadcrumbs, addBreadCrumb, search, resetBreadCrumb, setSearch} =
        useProductContext();
    const {favourites, category = false} = useQueryParams();

    useEffect(() => {
        let searching = false;

        setLoader(true);
        setItems([]);
        resetBreadCrumb();

        const promises = {};
        promises.categories = getCategories(category);

        searching = typeof search === 'string' && search.length > 0;

        if (searching) {
            promises.breadCrumbData = getBreadCrumb(category);
            promises.items = searchItems(category, search);
        } else if (category) {
            promises.breadCrumbData = getBreadCrumb(category);
            promises.items = getItems(category);
        } else if (favourites) {
            promises.items = getFavouriteItems();
        }

        return usePromise(
            ({categories, items, breadCrumbData}) => {
                if (categories) setCategories(categories);

                if (items) setItems(items.sundry_items);

                if (breadCrumbData)
                    addBreadCrumb(mapBreadcrumb(breadCrumbData, jobId));

                favourites &&
                    breadcrumbs.length === 0 &&
                    addBreadCrumb(favouriteBreadcrumb(jobId));

                if (items && items.sundry_items.length === 0 && searching) {
                    throw new Error();
                }

                setLoader(false);
            },
            promises,
            (error) => {
                if (error && searching) {
                    genericMessageHandler(
                        notify,
                        {
                            message: `No Items found with the keyword "${search}". Please try another term.`,
                        },
                        'warning',
                        false
                    );
                    setSearch('');
                } else genericMessageHandler(notify, error);

                setLoader(false);
            },
            () => {}
        );
    }, [category, search, favourites]);

    return {
        loader,
        categories,
        items,
    };
};

export const useHardwareItem = (sundry = false, itemId = null) => {
    const {jobId} = useParams();
    const {notify} = useNotificationContext();
    const {breadcrumbs, addBreadCrumb} = useProductContext();
    const {category = false, favourites = false} = useQueryParams();
    const [details, setDetails] = useState({});
    const [loading, setLoading] = useState(false);
    const [dropdownLoading, setDropdownLoading] = useState(false);
    const [itemVariation, setItemVariation] = useState({});
    const [advancedAttributes, setAdvancedAttributes] = useState({});
    const [advancedAttributeValues, setAdvancedAttributeValues] = useState({});
    const [count, setCount] = useState(1);
    const [selectedAdvancedAttributes, setSelectedAdvancedAttributes] =
        useState({});
    const [selectedVariations, setSelectedVariations] = useState([]);
    const itemVariations = useRef([]);
    const attributeIdStore = useRef({});
    const itemDetailsStore = useRef({});
    const {data: jobsSundryItem} = useGetJobSundryItemsQuery({
        data: {
            job_id: Number(jobId) - 10000,
        },
    });
    const updatedValues = cloneDeep(advancedAttributeValues);
    const {data: jobSundryAttributes} = useGetJobSundryAttributesQuery({
        id: Number(itemId),
    });

    const getSelectedVariation = (selectedValues) => {
        return itemVariations.current.find((variation) => {
            return Object.values(variation.variation_attributes).every(
                (attribute) => {
                    return (
                        selectedValues.hasOwnProperty(attribute.id) &&
                        selectedValues[attribute.id] == attribute.value
                    );
                }
            );
        });
    };

    const setAttributeValue = (name, value) => {
        updatedValues[name] = value;
        setAdvancedAttributeValues(updatedValues);
        setItemVariation(getSelectedVariation(updatedValues));
    };

    useEffect(() => {
        setAdvancedAttributeValues({});
        setLoading(true);
        if (sundry) {
            const itemPromise = getItem(sundry);
            const breadCrumbPromise =
                category && breadcrumbs.length === 0 && getBreadCrumb(category);

            return usePromise(
                ([itemDetails, breadCrumbData]) => {
                    itemDetailsStore.current = itemDetails;
                    setDetails(itemDetails);
                    breadCrumbData &&
                        addBreadCrumb(mapBreadcrumb(breadCrumbData, jobId));
                    favourites &&
                        breadcrumbs.length === 0 &&
                        addBreadCrumb(favouriteBreadcrumb(jobId));
                    setLoading(false);
                },
                [itemPromise, breadCrumbPromise],
                (error) => {
                    genericMessageHandler(notify, error);
                    setLoading(false);
                }
            );
        }
    }, [sundry, itemId]);

    useEffect(() => {
        itemId && jobsSundryItem
            ? setCount(
                  jobsSundryItem.job_sundry_items[parseInt(itemId)].quantity
              )
            : setCount(1);
    }, [itemId, jobsSundryItem]);

    useEffect(() => {
        const attributesOptions = {};
        if (details.advanced) {
            return usePromise(
                ([attributes, variations]) => {
                    itemVariations.current = variations;
                    Object.keys(attributes).forEach((attribute) => {
                        attributesOptions[attribute] = [];
                        Object.keys(attributes[attribute].values).forEach(
                            (key) => {
                                const value = attributes[attribute].values[key];
                                attributesOptions[attribute].push({
                                    id: value,
                                    name: value,
                                    selected:
                                        selectedVariations.indexOf(value) > -1
                                            ? true
                                            : false,
                                    key,
                                });
                            }
                        );
                    });
                    Object.keys(attributesOptions).forEach((attribute) => {
                        attributesOptions[attribute].sort((a, b) =>
                            a.name > b.name ? 1 : b.name > a.name ? -1 : 0
                        );
                    });
                    setAdvancedAttributes(attributesOptions);
                    setDropdownLoading(false);
                },
                [
                    getAdvancedAttributes(sundry),
                    getHardwareVariations(sundry),
                    jobSundryAttributes,
                ],
                (error) => {
                    genericMessageHandler(notify, error);
                    setDropdownLoading(false);
                    setLoading(false);
                }
            );
        } else {
            setAdvancedAttributes({});
            setLoading(false);
        }
    }, [details, itemId, selectedVariations]);

    useEffect(() => {
        if (jobSundryAttributes) {
            const advancedAttributesObj = {};
            const selectedVariationArr = [];

            Object.keys(jobSundryAttributes['job_sundry_attributes']).forEach(
                (key) => {
                    const attribute =
                        jobSundryAttributes['job_sundry_attributes'][
                            parseInt(key)
                        ];
                    selectedVariationArr.push(attribute.value);
                    advancedAttributesObj[attribute.name] = attribute.value;
                }
            );

            setSelectedAdvancedAttributes(advancedAttributesObj);
            setSelectedVariations(selectedVariationArr);
            if (details['item_attributes']) {
                Object.keys(details['item_attributes']).forEach((key) => {
                    const detailAttr =
                        details['item_attributes'][parseInt(key, 10)];
                    const jobAttr =
                        jobSundryAttributes['job_sundry_attributes'][
                            parseInt(key, 10)
                        ];

                    // Check if both detailAttr and jobAttr are defined
                    if (detailAttr && jobAttr) {
                        // Safely check if both have the 'name' property and compare
                        if (
                            detailAttr.name === jobAttr.name &&
                            detailAttr.freeText === 1
                        ) {
                            setAttributeValue(detailAttr.id, jobAttr.value);
                        }
                    }
                });
            }
        }
    }, [details, jobSundryAttributes]);

    return {
        loading,
        details,
        advancedAttributes,
        advancedAttributeValues,
        attributeIdStore,
        itemVariation,
        setAttributeValue,
        dropdownLoading,
        count,
        setCount,
        selectedAdvancedAttributes,
        jobSundryAttributes,
    };
};

// Hook for canvas preview
export const useProductCanvasPreview = (showEdges = false) => {
    const position = useStickyCanvas();
    const {values} = useFormikContext();
    const {productDataStore, currentTab} = useProductContext();
    const canvasContainerId = useRef(
        uniqueId('canvas-container-size-preview-')
    ).current;

    const updateProductPreview = ({values, componentPainter}) => {
        const productTypes = {
            DOOR: 8,
            DRAWER_FACE: 10,
            APPLIED_PANEL: 3,
            FILLER: 2,
            U_SHAPE: 7,
            DRAWER_CARCASE: 9,
            MANUAL_PANEL: 4,
        };
        const productStyle = {COMPONENT: 5, UPPER: 3};
        const productSubStyle = {MANUAL_PANEL: 36};

        let width = values.cabinet_width;
        let height = values.cabinet_height;
        let horizontalLabel;
        let verticalLabel;
        const options = {
            type: COMPONENT_TYPES.RECT,
            height: parseInt(height),
            width: parseInt(width),
            rotated: false,
        };

        if (showEdges) {
            options.edging = {
                edgeLength1: parseInt(
                    values.cabinet_edge_L1 ? values.cabinet_edge_L1 : 0
                ),
                edgeLength2: parseInt(
                    values.cabinet_edge_L2 ? values.cabinet_edge_L2 : 0
                ),
                edgeWidth1: parseInt(
                    values.cabinet_edge_W1 ? values.cabinet_edge_W1 : 0
                ),
                edgeWidth2: parseInt(
                    values.cabinet_edge_W2 ? values.cabinet_edge_W2 : 0
                ),
            };
        }

        const cabinet = productDataStore.current.cabinet.attributes;

        if (
            ['kick length', 'kicklength'].indexOf(
                cabinet.description.toLowerCase()
            ) >= 0 ||
            cabinet.original_name.toLowerCase().indexOf('kick length') >= 0 ||
            cabinet.description.toLowerCase().indexOf('kick length') >= 0
        ) {
            height = values.cabinet_panel_width;
            width = values.cabinet_panel_length;
            horizontalLabel = 'Width';
            verticalLabel = 'Height';
        } else if (cabinet.product_type == productTypes.APPLIED_PANEL) {
            height = parseFloat(values.cabinet_height);
            if (
                cabinet.style != productStyle.UPPER &&
                values.hasOwnProperty('cabinet_extend') &&
                values.cabinet_extend
            ) {
                height += parseFloat(values.cabinet_toekick);
            }
            width = values.cabinet_applied_panel_depth;
            verticalLabel = 'Depth';
            options.rotated = true;
        } else if (cabinet.product_type == productTypes.FILLER) {
            height = parseFloat(values.cabinet_height);
            if (
                cabinet.style != productStyle.UPPER &&
                values.hasOwnProperty('cabinet_extend') &&
                values.cabinet_extend
            ) {
                height += parseFloat(values.cabinet_toekick);
            }
            width = values.cabinet_width;
            options.rotated = true;
        } else if (
            cabinet.product_type == productTypes.DOOR ||
            cabinet.product_type == productTypes.DRAWER_FACE ||
            (cabinet.product_type == productTypes.MANUAL_PANEL &&
                cabinet.sub_style_id == productSubStyle.MANUAL_PANEL)
        ) {
            height = values.cabinet_panel_length;
            horizontalLabel = 'Height';
            width = values.cabinet_panel_width;
            options.rotated = true;
        } else {
            height = values.cabinet_panel_length;
            horizontalLabel = 'Length';
            width = values.cabinet_panel_width;
        }

        if (
            typeof options.innerRadius == 'undefined' &&
            values.hasOwnProperty('cabinet_int_radius')
        ) {
            options.innerRadius = values.cabinet_int_radius;
        }
        if (
            typeof options.exteriorRadius == 'undefined' &&
            values.hasOwnProperty('cabinet_ext_radius')
        ) {
            options.exteriorRadius = values.cabinet_ext_radius;
        }

        // USHAPE
        if (
            cabinet.product_type == productTypes.U_SHAPE ||
            cabinet.original_name
                .replace(/\s/g, '')
                .toLocaleLowerCase()
                .indexOf('ushape') >= 0
        ) {
            options.type = COMPONENT_TYPES.U_SHAPE;
            options.length1 = values.cabinet_length1;
            options.length2 = values.cabinet_length2;
            options.width1 = values.cabinet_width1;
            options.interiorRadius = values.cabinet_int_radius;
            options.exteriorRadius = values.cabinet_ext_radius;
        }

        // Key Shape
        if (
            cabinet.keyShape ||
            cabinet.original_name
                .replace(/\s/g, '')
                .toLocaleLowerCase()
                .indexOf('keyshape') >= 0
        ) {
            options.type = COMPONENT_TYPES.KEY_SHAPE;
            options.leftWidth = width;
            options.rightWidth = height;
            options.leftDepth = values.cabinet_width_depth;
            options.rightDepth = values.cabinet_length_depth;

            if (showEdges) {
                options.edging.innerRadius = parseInt(
                    values.hasOwnProperty('cabinet_edge_inner')
                        ? values.cabinet_edge_inner
                        : 0
                );
            }
        }

        // Lshape
        if (
            cabinet.lshape == 1 ||
            cabinet.original_name
                .replace(/\s/g, '')
                .toLocaleLowerCase()
                .indexOf('lshape') >= 0
        ) {
            options.type = COMPONENT_TYPES.L_SHAPE;
            options.leftWidth = width ? width : values.cabinet_left_width;
            options.rightWidth = height ? height : values.cabinet_right_width;
            options.leftDepth = values.cabinet_width_depth
                ? values.cabinet_width_depth
                : values.cabinet_left_depth;
            options.rightDepth = values.cabinet_length_depth
                ? values.cabinet_length_depth
                : values.cabinet_right_depth;
            options.insideRadius = values.cabinet_int_radius;

            if (showEdges) {
                options.edging.innerRadius = parseInt(
                    values.hasOwnProperty('cabinet_edge_inner')
                        ? values.cabinet_edge_inner
                        : 0
                );
            }
        }

        // 45 degree cabinets, 3 tier cabinets
        if (cabinet.lshape == 2 || cabinet.lshape == 5) {
            options.type = COMPONENT_TYPES.PENTAGON;
            options.leftWidth = width ? width : values.cabinet_left_width;
            options.rightWidth = height ? height : values.cabinet_right_width;
            options.leftDepth = values.cabinet_width_depth
                ? values.cabinet_width_depth
                : values.cabinet_left_depth;
            options.rightDepth = values.cabinet_length_depth
                ? values.cabinet_length_depth
                : values.cabinet_right_depth;
        }

        options.height = height;
        options.width = width;

        if (horizontalLabel) {
            options.horizontalLabel = horizontalLabel;
        }
        if (verticalLabel) {
            options.verticalLabel = verticalLabel;
        }

        // ladder/kicker frame
        if (values.hasOwnProperty('cabinet_ladder_frames')) {
            options.type = COMPONENT_TYPES.LADDER;

            options.width = parseInt(values.cabinet_width);
            options.depth = parseInt(values.cabinet_depth);
            options.ladderFrames = parseInt(values.cabinet_ladder_frames);

            if (showEdges) {
                options.edging = {
                    edgeLength1: values.cabinet_edge_L1
                        ? values.cabinet_edge_L1
                        : false,
                    edgeLength2: values.cabinet_edge_L2
                        ? values.cabinet_edge_L2
                        : false,
                    edgeWidth1: values.cabinet_edge_W1
                        ? values.cabinet_edge_W1
                        : false,
                    edgeWidth2: values.cabinet_edge_W2
                        ? values.cabinet_edge_W2
                        : false,
                };
            }
        }

        componentPainter.init(options);
    };

    const debouncedEffect = useRef(
        throttle(updateProductPreview, 1000, {
            leading: false,
            trailing: true,
            maxWait: 1000,
        })
    ).current;

    useEffect(() => {
        const componentPainter = new ComponentPainter(canvasContainerId, 350);

        debouncedEffect({values, componentPainter});
    }, [values, currentTab]);

    return {position, canvasContainerId};
};
