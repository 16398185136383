import {useCallback} from 'react';
import {
    SidebarStatus,
    SidebarBehaviour,
    SidebarName,
    SidebarBehaviourName,
    setRightSidebarStatus,
    setRightSidebarBehaviour as setRightSidebarBehaviourAction,
    setLeftSidebarStatus,
    setLeftSidebarBehaviour as setLeftSidebarBehaviourAction,
    selectRightSidebar,
    selectRightSidebarBehaviour,
    selectLeftSidebar,
    selectLeftSidebarBehaviour,
} from 'store/customer/UISlice';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {useAppContext} from 'contexts';

export const SIDEBAR = {
    LEFT: 'left',
    RIGHT: 'right',
};

export const useSidebarBehaviour = () => {
    const dispatch = useAppDispatch();
    const {alternateCartContent, setAlternateCartContent} = useAppContext();

    const rightSidebar = useAppSelector(selectRightSidebar);
    const rightSidebarBehaviour = useAppSelector(selectRightSidebarBehaviour);

    const leftSidebar = useAppSelector(selectLeftSidebar);
    const leftSidebarBehaviour = useAppSelector(selectLeftSidebarBehaviour);

    // NOTE: USE this when it is determined why keeping alternateCartContent
    // in redux store is killing the UI
    // WHEN that happens remove alternateCartContent from the useAppContext
    // and do code cleanup where necessary
    // const alternateCartContent = useAppSelector(
    //     selectAlternateCartContent,
    //     shallowEqual
    // );

    const setRightSidebar = useCallback((status: SidebarStatus) => {
        localStorage.setItem(SidebarName.RIGHT, status);
        dispatch(setRightSidebarStatus(status));
    }, []);

    const setRightSidebarBehaviour = useCallback(
        (behaviour: SidebarBehaviour) => {
            localStorage.setItem(SidebarBehaviourName.RIGHT, behaviour);
            dispatch(setRightSidebarBehaviourAction(behaviour));
        },
        []
    );

    const setLeftSidebar = useCallback((status: SidebarStatus) => {
        localStorage.setItem(SidebarName.LEFT, status);
        dispatch(setLeftSidebarStatus(status));
    }, []);

    const setLeftSidebarBehaviour = useCallback(
        (behaviour: SidebarBehaviour) => {
            localStorage.setItem(SidebarBehaviourName.LEFT, behaviour);
            dispatch(setLeftSidebarBehaviourAction(behaviour));
        },
        []
    );

    // NOTE: USE this when it is determined why keeping alternateCartContent
    // in redux store is killing the UI
    // const setAlternateCartContent = useCallback((content: ReactElement) => {
    //     if (typeof content == 'undefined') {
    //         dispatch(setAlternateCartContentAction(undefined));

    //         return;
    //     }

    //     dispatch(setAlternateCartContentAction(content));
    // }, []);

    return {
        alternateCartContent,
        leftSidebar,
        leftSidebarBehaviour,
        rightSidebar,
        rightSidebarBehaviour,
        setAlternateCartContent,
        setRightSidebar,
        setRightSidebarBehaviour,
        setLeftSidebar,
        setLeftSidebarBehaviour,
    };
};
